#sidebar {
	height: 100%;
	position: fixed;
	width: 240px;
	left: -240px;

	@media (min-width: $md) {
		width: 280px;
		left: -280px;
	}

	/*height: 100px;*/
	z-index: 999;
	background: #d9d1c8;
	color: #fff;
	/*overflow-y: scroll;*/
	/*box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);*/
}

#sidebar.active {
	left: 0;
	position: absolute;

}

.navbar-collapse {
	max-height: 100% !important;
}

.menu {
	color: #000;
	margin: 1rem .8rem 0 .8rem;
	padding-top: 5px;

	input {
		background-color: #D9D1C8;
		font-size: 1rem;
		font-weight: bold;
	}

	&--separ {
		display: block;
		border: 1px solid #000;
		width: 50px;
		margin-left: .6rem;
		padding-left: 0;
	}

	&--title {
		font-size: 1rem;
		font-weight: bold;
	}

	&--leftborder {
		margin: 0 0 0 5px;
		font-size: .8rem;
		padding-left: 10px;
		border-left: 2px solid #8f8d88;
	}

	&--leftborder:first-child {
		padding-top: 0 !important;
	}

	/*
    &--leftborder:last-child {
        background-color: lime;
        padding-bottom: 0 !important;
    }
    */
}

.menu.color-menu-grey .menu--separ {
	border: 1px solid #8f8d88;
}


a.anchored {
	display: block;
	position: relative;
	top: calc(-1*$header-height);

	@media (min-width: $md) {
		top: calc(-1*$header-height-md);
	}

	visibility: hidden;
}

.arrow_box {
	position: relative;
	background: #88b7d5;
	border: 4px solid #c2e1f5;
}

.map-wrapper:after,
.map-wrapper:before {
	top: 100%;
	left: 50%;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.tiles-desktop .tile-desktop-1:after,
.tiles-desktop .tile-desktop-1:before {
	top: 100%;
	left: 50%;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.tiles-desktop .tile-desktop-1:after {
	border-color: rgba(136, 183, 213, 0);
	border-top-color: #FAF9F7;
	border-width: 19px;
	margin-left: 98px;
}

.tiles-desktop .tile-desktop-1:before {
	border-color: rgba(194, 225, 245, 0);
	border-top-color: #D9D1C8;
	border-width: 20px;
	margin-left: 97px;
}

.map-wrapper {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: right top;
	min-width: 420px;
	padding-bottom: 7.35%;
	padding-left: 0;

	@media (min-width: $md) {
		max-height: 856px;
		min-width: 450px;
		padding-bottom: 3%;
		/* maintain background aspect ratio */
		flex: 0 0 55.333333%;
		max-width: 55.333333%;
	}
}

/*
.cart {
	min-width: 281px;

	@media (min-width: $md) {
		padding: 0 55px;
		position: relative;
		top: -20px;
	}
}
*/

.companies .cart {
	@media (min-width: $md) {
		top: 62px !important;
	}
}

.handwritten {
	font-family: 'Chalk Duster';
	font-size: 1.125rem;
	text-align: center;
	margin-top: -12px;
	min-width: 300px;

	@media (min-width: $md) {
		margin-bottom: 2%;
		min-width: 425px;
		position: relative;
		top: -20px;
	}

	&--underscore {
		width: 151px;
		margin-left: auto;
		margin-right: auto;
	}
}

.companies .handwritten {
	@media (min-width: $md) {
		top: 0px;
	}
}

.sharing {
	ul {
		list-style-type: none;
		margin: 0 auto;
		padding: 5px 0;
		text-align: center;

		li {
			margin: 0 1rem;
			display: inline;
		}
	}


	&__link {
		display: inline;
		color: $redGrey;
		font-size: 11px;
		font-weight: 400;
		text-transform: uppercase;
		text-decoration: underline;
		margin: 1rem 0;
	}

	&-message {
		color: #ee5535;
		font-size: .625rem;
	}
}

.popover {
	border: 1px solid #D9D3CC;

}

.sharing2 {
	width: 250px;
	height: 100px;
	padding: 0 .5rem;

	ul {
		list-style-type: none;
		margin: 1rem auto;
		/*centrage marges automatiques*/
		text-align: center;

		li {
			margin: 0 1rem;
			display: inline;
		}
	}

}

.ur {
	width: 100%;
	border-bottom: solid 1px #bebab5;
	margin-bottom: 1rem;
	padding-bottom: .4rem;
	color: #000;
	font-size: .875rem;
	font-weight: 600;

	&__label {
		margin-top: .5rem;
		margin-bottom: .2rem;
		color: $redGrey;
		font-size: .625rem;
	}
}

.ur.focus,
.ur:focus {
	outline: 0;
	box-shadow: none !important
}

.cpy {
	cursor: pointer;
	color: $redGrey;
	/*font-size: .6875rem;*/
	font-size: 1rem;
	font-weight: 600;
	text-decoration: underline;
}


.bordered {
	/*object-fit: contain;*/
	border: solid 0.5px #bebab5;
	white-space: nowrap;
	color: $redGrey;
	font-size: .86rem;
	padding: 1rem;
	margin: .5rem;
	/*text-justify: inter-word;*/

	img {
		display: inline;
	}
}

.well {
	display: flex;
	align-items: center;
}

.blue-box {
	/*background-color: rgba(0, 159, 227, 0.5);*/
	background-color: #29B5E7;
	position: relative;
	margin: .5rem;
	padding: 0 1rem;
	text-align: center;

}


.grey-box {
	background-color: #D9D1C8;
	height: 200px;

	position: relative;

	margin: .5rem;
	padding: 2rem .5rem;
	text-align: center;
}

.grey-box.full-height {
	height: auto !important;
}

.grey-box2 {
	background-color: #D9D1C8;
	height: 300px;

	position: relative;

	margin: .5rem;
	padding: 2rem .5rem;
	text-align: center;
}


.white-box {
	background-color: transparent;
	border: 1px solid #D9D1C8;

	position: relative;

	margin: .5rem;
	padding: 2rem .5rem;
	text-align: center;
}

.white-box-nomark {
	background-color: transparent;
	border: 1px solid #D9D1C8;

	position: relative;

	margin: .5rem;
	padding: 2rem .5rem;
	text-align: center;
}

/*
.yellow-box {
	background-color: transparent;
	border: 1px solid $socialYellow;

	position: relative;

	margin: .5rem;
	padding: 2rem .5rem;
	text-align: center;
}
*/

/*.blue-box:before,*/
.green-box:before,
.yellow-box:before,
.grey-box:before,
.map-wrapper:before {
	top: 0%;
	left: 50%;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;

	border-color: rgba(194, 225, 245, 0);
	border-top-color: #fff;
	border-width: 23px 29.5px 0 29.5px;
	margin-left: -28px;
	margin-top: -2px;
}

.tiles-desktop .tile-desktop-1:before,
.tiles-desktop .tile-desktop-1:after {
	top: 100%;
	left: 50%;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(194, 225, 245, 0);
	border-top-color: #fff;
	border-width: 23px 29.5px 0 29.5px;
	margin-left: -28px;
	margin-top: -2px;
}



.tiles-desktop .tile-desktop-2:before,
.tiles-desktop .tile-desktop-2:after {
	top: 50%;
	left: 0%;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(194, 225, 245, 0);
	border-top-color: #fff;
	border-width: 23px 29.5px 0 29.5px;
	margin-left: -28px;
	margin-top: -2px;
}

.tiles-desktop .tile-desktop-3:before,
.tiles-desktop .tile-desktop-3:after {
	top: 50%;
	right: 0%;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(194, 225, 245, 0);
	border-top-color: #fff;
	border-width: 23px 29.5px 0 29.5px;
	margin-left: -28px;
	margin-top: -2px;
}

.tiles-desktop .tile-desktop-4:before,
.tiles-desktop .tile-desktop-4:after {
	top: 0%;
	left: 50%;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(194, 225, 245, 0);
	border-top-color: #fff;
	border-width: 23px 29.5px 0 29.5px;
	margin-left: -28px;
	margin-top: -2px;
}



.tiles-desktop .tile-desktop-1:before {
	border-style: solid;
	border-width: 0 28.5px 22px 28.5px;
	border-color: transparent transparent rgba(1, 190, 98, 0.5) transparent;
	margin-left: -28px;
	margin-top: -21px;
}

.tiles-desktop .tile-desktop-1:after {
	border-style: solid;
	border-width: 0 28.5px 22px 28.5px;
	border-color: transparent transparent #FAF9F7 transparent;
	margin-left: -28px;
	margin-top: -20px;
}



.tiles-desktop .tile-desktop-2:before {
	border-style: solid;
	border-width: 28.5px 0 28.5px 22px;
	border-color: transparent transparent transparent rgba(1, 190, 98, 0.5);
	margin-left: -1px;
	margin-top: -23px;
}

.tiles-desktop .tile-desktop-2:after {
	border-style: solid;
	border-width: 28.5px 0 28.5px 22px;
	border-color: transparent transparent transparent #FAF9F7;
	margin-left: -2px;
	margin-top: -23px;
}

.tiles-desktop .tile-desktop-3:before {
	border-style: solid;
	border-width: 28.5px 22px 28.5px 0;
	;
	border-color: transparent rgba(1, 190, 98, 0.5) transparent transparent;
	margin-right: -1px;
	margin-top: -23px;
}

.tiles-desktop .tile-desktop-3:after {
	border-style: solid;
	border-width: 28.5px 22px 28.5px 0;
	;
	border-color: transparent #FAF9F7 transparent transparent;
	margin-right: -2px;
	margin-top: -23px;
}

.tiles-desktop .tile-desktop-4:before {
	border-style: solid;
	border-width: 22px 28.5px 0 28.5px;
	border-color: rgba(1, 190, 98, 0.5) transparent transparent transparent;
	margin-left: -28px;
	margin-top: 0px;
}

.tiles-desktop .tile-desktop-4:after {
	border-style: solid;
	border-width: 22px 28.5px 0 28.5px;
	border-color: #FAF9F7 transparent transparent transparent;
	margin-left: -28px;
	margin-top: -1px;
}

.box--white {
	display: block;
	border-color: 1px solid #86827E;
	background-color: #fff;
	padding: 1rem;

	/*
	margin: 1rem;
	@media (min-width: $md) {
		margin: -1rem 1rem 1rem 1rem;
	}
	*/

	text-align: left;
	font-size: 12px;
	font-weight: 600;
	line-height: 15px;
}

.box--white2 {
	/*border-color: 1px solid #86827E;*/
	background-color: #FAF9F7;
	padding: 1rem;
	text-align: center;
	font-size: 14px;
	line-height: 22px;
}

/*
.box--map {
	border-color: 1px solid #86827E;
	background-color: #fff;
	text-align: left;
	font-size: 14px;
	line-height: 22px;
	height: 420px;
	width: 100%;
}
*/




.white-box:after,
.white-box:before {
	top: 0%;
	left: 50%;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.white-box:after {
	border-color: rgba(136, 183, 213, 0);
	border-top-color: #fff;
	border-width: 23px 29.5px 0 29.5px;
	margin-left: -28px;
	margin-top: -2px;
}

.white-box:before {
	border-color: rgba(194, 225, 245, 0);
	border-top-color: #D9D1C8;
	border-width: 23px 29.5px 0 29.5px;
	margin-left: -28px;
	margin-top: -1px;
}


.charter-wrapper {
	position: relative;
}

.charter-wrapper.arrow-bottom:before {
	top: 100%;
	left: 50%;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(194, 225, 245, 0);
	border-top-color: #FAF9F7;
	border-width: 23px 29.5px 0 29.5px;
	margin-left: -28px;
	margin-top: -2px;
}

.green-box {
	background-color: rgba(1, 190, 98, 0.5);
	padding: 2rem 1rem;
	position: relative;
}


.yellow-box {
	/*background-color: rgba(255, 237, 0, 0.5);*/
	background-color: $socialYellow;
	width: 100%;
	position: relative;

	height: 300px;

	/*
	margin-top: 2rem;
	margin-bottom: 2rem;
	padding: 2rem .5rem;

	@media (min-width: $md) {
		padding: 3rem 0;
	}

	*/

	text-align: center;
}

.number-svg {
	height: 30px;
	display: block;
	margin-bottom: 1rem;

	@media (min-width: $md) {
		display: inline;
		height: 50px;
	}
}

.manual--spaced-lines {
	line-height: 1.44rem;
}

#illu-warranties {
	width: 320px;
	height: 198px;

	@media (min-width: $md) {
		width: 640px;
		height: 396px;
	}
}

#illu-warranties-other {
	width: 320px;

	@media (min-width: $md) {
		width: 640px;
	}
}

#illu-warranties-sante {
	width: 358px;
	height: 244px;


	@media (min-width: $md) {
		width: 476px;
		height: 324px;
	}

}

#illu-usage {
	width: 320px;
	height: 204px;

	@media (min-width: $md) {
		width: 640px;
		height: 410px;
	}
}

#illu-usage-sante {
	width: 320px;
	height: 180px;

	@media (min-width: $md) {
		width: 640px;
		height: 360px;
	}
}


.companies #illu-warranties {
	width: 320px;

	@media (min-width: $md) {
		width: 812px;
	}
}

#illu-philosophy {
	width: 384px;
	height: 216px;

	@media (min-width: $md) {
		width: 768px;
		height: 432px;
	}
}

#illu-philosophy_other {
	width: 384px;
	height: 384px;

	@media (min-width: $md) {
		width: 640px;
		height: 640px;
	}
}

#illu-charter {
	width: 355px;
	height: 200px;

	@media (min-width: $md) {
		width: 710px;
		height: 399px;
	}
}

.tiles {
	background-color: $mainWhite;
	width: 100%;

	@media (min-width: $md) {
		background-image: url("/img/blocs/philosophie_decoupe.svg");
		background-size: contain;
		background-repeat: no-repeat;
		background-position: right top;
		padding-bottom: 18px;
		overflow: hidden;
	}

	@media (min-width: 1200px) {
		padding-bottom: 0px;
		background-size: auto;
	}

	@media (max-width: 1024px) {
		padding-bottom: 0;
	}

	.row {
		@media (min-width: $md) {
			height: 50%;
		}
	}
}

#tile1,
#tile2 {
	@media (min-width: $md) and (max-width: 1024px) {
		margin-top: 38px;
	}

	@media (min-width: 1024px) {
		margin-top: -10px
	}
}

#tile3,
#tile4 {
	@media (min-width: $md) and (max-width: 1024px) {
		margin-top: 12pxpx;
	}

	@media (min-width: 1024px) and (max-width: 1200px) {
		margin-top: -48px
	}

	@media (min-width: 1200px) {
		margin-top: 9px
	}

}

.tile#tile4 {
	@media (max-width: 624px) {
		padding-bottom: 11% !important;
	}
}

.tile {
	text-align: center;
	padding-top: 2rem;
	padding-left: 2rem;
	padding-right: 2rem;

	@media (min-width: 1024px) {
		padding-left: 73px;
		padding-right: 73px;
	}

	@media (min-width: $md) {
		height: 100%;
		padding-top: 14%;
		padding-bottom: 14%;
	}

	@media (max-width: 1024px) {
		padding-top: 6%;
		padding-bottom: 12%;
	}

	@media (max-width: 624px) {
		padding-top: 14%;
		padding-bottom: 0%;
	}


	hr {
		border: 1px solid $mainGreen;
		width: 50px;
		margin-top: 1rem;
		margin-bottom: 1rem;
	}


	&--collapse-link {
		display: inline;

		@media (min-width: $md) {
			display: none;
		}
	}

	&--collapse-title {
		display: none;

		@media (min-width: $md) {
			display: inline;
		}
	}
}

#tile_1 {
	@media (min-width: $md) {
		display: inline;
	}
}

#tile_2 {
	@media (min-width: $md) {
		display: inline;
	}
}

#tile_3 {
	@media (min-width: $md) {
		display: inline;
	}
}

#tile_4 {
	@media (min-width: $md) {
		display: inline;
	}
}

.separ {
	width: 100%;
	text-align: center;
	padding-top: 2rem;

	@media (min-width: $md) {
		display: none;
	}
}

.charter-wrapper {
	background-color: $mainWhite;
	width: 100%;
	font-size: .86rem;
	padding: 1rem;
	margin: .5rem;

}

.charter-content {
	box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
	background-color: #fff;
	padding: 2rem 1rem 1rem 1rem;
	margin-bottom: 2rem;

	@media (min-width: $md) {
		padding: 2rem;
		padding-left: 51px;
		padding-right: 51px;
		padding-top: 40px
	}
}

.title-mode-emploi {
	font-size: 15px;
	font-weight: 600;
}

.fleche-verte {
	display: inline-block;
}

.fleche-verte-texte {
	display: inline-grid;
	padding-left: 5px;
}

.tiles-desktop {
	.col-6 {
		padding-top: 14%;
		padding-bottom: 14%;
		padding-left: 73px;
		padding-right: 73px;
	}

	.tile-desktop-1 {
		border-bottom: 1px solid rgba(1, 190, 98, 0.5);
		border-right: 1px solid rgba(1, 190, 98, 0.5);
	}

	.tile-desktop-2 {
		border-bottom: 1px solid rgba(1, 190, 98, 0.5);
	}

	.tile-desktop-3 {
		border-right: 1px solid rgba(1, 190, 98, 0.5);
	}

	.tile-desktop-4 {}
}

#number-svg-1 {
	content: url("/img/chiffres/noir/01.png");
}

#number-svg-1.active {
	content: url("/img/chiffres/vert/01.png");
}

#number-svg-2 {
	content: url("/img/chiffres/noir/02.png");
}

#number-svg-2.active {
	content: url("/img/chiffres/vert/02.png");
}

#number-svg-3 {
	content: url("/img/chiffres/noir/03.png");
}

#number-svg-3.active {
	content: url("/img/chiffres/vert/03.png");
}

.link--greenborder.active {
	background-color: #80DEB0;
}

.footer-logo {
	margin: 0 auto;
}

.texte-garantie {
	line-height: 21px;
}

.bouton-garantie {
	border: 1px solid rgba(1, 190, 98, 0.5);

	/*
	padding: 7px;
	margin: 7px 0;
	@media (min-width: $md) {
		padding: 15px 7px;
		margin: 15px 0;
	}
	*/

	font-size: 15px;
	font-weight: 700;

}

.d-md-block .bouton-garantie {
	width: 102px;
	text-align: center;
}

.bouton-garantie.active {
	background-color: rgba(1, 190, 98, 0.5);
	border-color: transparent;
}

.companies img.decouvrir {
	max-width: 715px;
	width: 715px;
	position: absolute;
	top: -26px;
	left: -78px;
}

.img-overlay {
	margin-top: -50px;
}

.box-little-header {
	font-weight: 600;
	font-size: 12px;

	@media (min-width: $md) {
		font-size: 12px;
	}
}


.embed-container {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 300px;

	@media (min-width: $md) {
		font-size: 12px;
		height: 600px;
	}

}


.embed-container iframe,
.embed-container object,
.embed-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.vertical-line {
	border-left: 1px solid;
	border-right: 1px solid;
}
