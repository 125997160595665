.public-h2 {
	display: block;
	font-weight: 700;
	font-size: 20px;
	line-height:26px;
	margin-bottom: 10px;
	text-align: center;

	@media (min-width: $md) {
		font-size: 24px;
		line-height:28px;
		margin-bottom: 15px;
	}
}

.public-h3 {
	display: block;
	font-weight: 700;
	font-size: 16px;
	line-height:20px;
	text-align: center;
	margin-bottom: 10px;
	
	@media (min-width: $md) {
		font-size: 20px;
		line-height:24px;
		margin-bottom: 15px;
	}
}

.public-subtitle {
	display: block;
	font-weight: 700;
	font-size: 13px;
	line-height:16px;
	text-align: center;
	margin-bottom: 10px;
	
	@media (min-width: $md) {
		font-size: 16px;
		line-height:20px;
		margin-bottom: 15px;
	}
}

.public-h4 {
	display: block;
	font-weight: 700;
	font-size: 13px;
	line-height:16px;
	margin-bottom: 10px;
	margin-top: 20px;
	
	@media (min-width: $md) {
		font-size: 16px;
		line-height:20px;
		margin-bottom: 15px;
		margin-top: 20px;
	}

	&--li {
		margin-top: 10px;	
	}
}

.public-p {
    text-align: justify;
	color: black;
	word-wrap: break-word !important;
	margin-top: 5px;
    margin-bottom: 5px;
	margin-left: 0;
    font-size: 12px !important;
	line-height: 16px;
	@media (min-width: $md) {
		font-size: 14px !important;
		margin-top: 10px;
		margin-bottom: 10px;
		line-height: 20px;
	}

    &--spaced {
        margin-top: 5px;
        margin-bottom: 20px;
		line-height: 20px;
    }
}


/* offset anchor for fixed header*/
:target::before {
    content: "";
    display: block;
    height: 80px; /* fixed header height*/
    margin: -80px 0 0; /* negative fixed header height */
  }

/* unordered list */
.public-p strong{
	font-weight: 700;
	font-style : italic;
}
.public-ul li{
	padding-left: 10px;
	margin-left: 5px;
    text-align: justify;
	list-style: '-' outside;
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: 12px !important;
	line-height: 16px;
	@media (min-width: $md) {
		font-size: 14px !important;
	    padding-left: 15px;
        line-height: 20px;
    }        
}

.public-ol li:before { 
	content: none;
}
.public-ol li { 
	margin-top: 10px;
    margin-bottom: 10px;
    text-align: justify;
    line-height: 16px;
	font-size: 12px !important;
	@media (min-width: $md) {
		font-size: 14px !important;
		line-height: 20px;
    }        
}
/* numerotation en dehors a gauche */
.ol-prefix { 
	/*margin-left: -30px;*/
	width: 20px;
	margin-right: 5px;
    font-weight: 700;
}

ul.taquet {
	list-style-image: url('/img/lignes/fleche_verte.svg');
    list-style-position: inside;
}

.cookie-disclaimer {
    background: #000000;
    color: #FFF;
    opacity: 0.8;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 1;
    height: 150px;
    position: fixed;
  }
  .cookie-disclaimer .container {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .cookie-disclaimer .cookie-close{
    float: right;
    padding: 10px;
    cursor: pointer;
  }
  