a.sidebar--link:hover {
    text-decoration: none !important;
}
.sidebar-links  {
    display: inline;
    
    a, p {
        font-size: 18px;
        color: #000;
        font-weight: 700;
        text-decoration: none;
    }
    .menu-active {
        color: rgba(1, 190, 98, 0.7) !important;
    }
    li.menu-active {
        border-color: rgba(1, 190, 98, 0.7) !important;
    }
    a.menu-active {
        text-decoration: none !important;
    }
    .menu-separ-user {
        margin-left: 0 !important;
        display: block;
        border: 1px solid #000;
        width: 50px;
        padding-left: 0;
        margin-bottom: 22px;
        margin-top: 7px;
    }
}
.heading .step-number {
    position: relative;
    height: 30px;
    display: inline;
    bottom: 2px;
}

.heading h2 {
    border-bottom: 2px solid #BEBAB5;
    padding-bottom: 13px !important;
}

.nav-tabs {
    border-bottom: 2px solid #BFBAB5;
    padding-bottom: 1px;
    /*border: none !important;*/
    
}

.nav-tabs.mes-reseaux {
    border-bottom: 2px solid #81DEAF;
}

.nav-link {
    color: #000 !important;
    font-size:16px !important;
    font-weight: 600 !important;
    border-radius: 0 !important;
    /*border: none !important;*/
    border-top: none !important;
    border-left: none !important;
    border-bottom: none !important;
    padding: 16px 28px !important;
    background-color: #EDE8E4 !important;
    @media (max-width: $md) {
        width:50%;
        font-size:15px;
        text-align:center;
        padding: 10px 11px !important;
    }
    border-right : 1px solid white !important;
    &:last-child{
        border-right: none !important;
    }
    
    
}

.nav-link.mes-reseaux {
    font-size:14px !important;
    font-weight: 600;
    text-transform: uppercase;
    background-color: transparent !important;
    padding-left: 0 !important;
    padding-right: 15px !important;
}
@media (min-width: $md) {
    .nav-link#nav-profile-tab {
        margin-left: 2%;
    }
}
.nav-link.active {
    background-color:     #BFEED7 !important;
    @media (min-width: $md) {
        background-color: #81DEAF !important;
    }
}
.nav-link.mes-reseaux.active {
    color: #81DEAF !important;
    background-color: transparent !important;
}
.synthese-title-green {
    font-size: 18px;
    color: #3ECE88;
    font-weight: 600;
}
.synthese-title-green.small {
    font-size: 12px;
    color: #3ECE88;
    font-weight: 600;
}
.synthese-title-green.arrowed:after {
    position: absolute;
    content: ' ';
    background-image: url(/img/pictos/fleche.svg);
    background-size: 34px 16px;
    height: 24px;
    width: 22px;
    background-repeat: no-repeat;
    right: 13px;
    top: 20px;
    @media (max-width: $lg) {
        display:none;
    }
}
.synthese-title-green.arrowed.middletop:after {
    top: 6%;
}
.synthese-content {
    font-size: 12px;
}

.box-heading {
    font-weight:600;
    font-size: 18px;
}

.border-bottom-grey {
    border-bottom: 2px solid #BFBAB5;
}

.summary {
    div {
        font-size:14px;
        hr {
            width: 100%;
            background-color: #BEBAB5;
            margin-top:6px
        }
        b {
            display: block;
            font-size:11px;
            font-weight: 600;
            color : #80DEB0;
            margin-bottom:5px;
            margin-bottom: 5px;
        }
    }
}

.bg-white {
    padding-top: 44px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    @media (min-width: $md) {
        padding-top: 44px !important;
        padding-left: 48px !important;
        padding-right: 48px !important;
    }
}

.bg-white.no-top-padding-mobile {
    @media (max-width: $md) {
        padding-top: 0px !important;
    }
}

.bg-white.no-padding-mobile {
    @media (max-width: $md) {
        padding-top: 44px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

.bg-white.no-padding-top-mobile {
    @media (max-width: $md) {
        padding-top: 0px !important;
    }
}

.bg-white.home {
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    @media (min-width: $md) {
        padding-top: 44px !important;
        padding-left: 48px !important;
        padding-right: 48px !important;
    }
}

.document-container {
    font-weight: 400;
    color: #46CF8C;
    background: #E5F9EF;
    display: block;
    width: 100%;
    text-align: center;
    padding: 20px;
}

.document-links {
    color: #86827E !important;
    font-size:12px;
    a {
        color: #86827E !important;
        font-size:12px;
        text-decoration: underline;
    }
}

.home-message-header {
    .folder-name {
        white-space: nowrap;
        text-transform: uppercase;
        color: #86827E;
        font-weight: 600;
        font-size: 12px;
    }
    .folder-name.active {
        color: rgba(1, 190, 98, 0.5);;
    }
}

span.unread {
    background-color: #81DEAF;
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 auto;
}

.grey-small {
    font-size: 12px;
    color: #86827E !important;
    padding-left: 5px;
    padding-right: 5px;
}


.date-grey {
    font-size: 12px;
    color: #86827E !important;
}

.bold-grey {
    font-weight: 600;
    color:#86827E;
    font-size: 12px;
}

.bold-value {
    font-weight: 600;
    font-size: 15px;
}



#accordionMailbox {
    thead {
        tr {
            border-bottom : 1px solid #BEBAB5;
            td, th {
                padding: 15px 0 15px 0;
            }
        }
    }
    tbody {
        tr {
            border-bottom : 1px solid #BEBAB5;
            td {
                padding: 15px 0 15px 0;
            }
        }
    }
}

#sidebar ul li.active>a, a[aria-expanded="true"] {
    color: #78CFA4;
    background: transparent;
    font-weight: 600;
}

.collapse-mail {
    background-color: #FAF9F7;
}

.weexes {
    tr.accordion-toggle {
        td {
            padding-left: 0 !important;
        }
    }
    .text--green {
        font-size: 11px;
        line-height: 24px;
        font-weight: 400;
        margin-bottom: 4px;
    }
    .text--grey {
        color: #BFBAB5 !important;
        font-size: 11px;
        line-height: 24px;
        font-weight: 400;
        margin-bottom: 4px;
    }
}

.weex-valider {
    background: transparent;
    color:rgba(0, 0, 0, 0.3);
    border: 1px solid #81DEAF;
    padding: 12px 14px;
    font-weight: 700;
}

.weex-valider.validated {
    background: #81DEAF;
    padding: 13px 15px;
    font-weight: 700;
}

.weex-refuser {
    background: transparent;
    color:rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(230, 81, 30, 0.7);
    padding: 12px 14px;
    font-weight: 700;
}

.weex-refuser.refused {
    background: rgba(230, 81, 30, 0.7);
    padding: 13px 15px;
    font-weight: 700;
    color: #000 !important;
}

.hiddenRow {
    padding: 0 !important;
    border-top: 0 !important;
    border-bottom: 1px solid #B2ADA9;
    
}

.table {
    max-width: none;
}

.table tr.accordion-toggle td {
    border-top: none;
    border-bottom: none;
    /*border-bottom: 1px solid #B2ADA9;*/
    padding-top: 26px;
    padding-bottom: 26px;
}

a.mglass_details {
    font-weight: 600;
    position: relative;
    font-size: 30px;
    right: -62px;
    top: 29px;
    @media (max-width: $xl) {
        position: initial;
        width: 100%;
        display: block;
        text-align: center;
        padding-top: 28px;
        padding-left: 12px;
    }
}
.weex-valide-acc {
    background: #FAF9F7;
}
table.weex-valide {
    border:none !important;
    td {
        border:none !important;
    }
    .heading {
        color: #000 !important;
        font-size: 11px;
        line-height: 24px;
        font-weight: 400;
        margin-bottom: 4px;
    }
}

table.label-table {
    margin-left: 5px;
    
    .heading {
        color: #000 !important;
        font-size: 11px;
        line-height: 24px;
        font-weight: 400;
        margin-bottom: 4px;
    }
}

.modal-weex {
    .big-black {
        font-size: 17px;
    }
    .heading {
        color: #000 !important;
        font-size: 11px;
        line-height: 24px;
        font-weight: 400;
        margin-bottom: 4px;
    }
    .value {
        font-size: 16px;
        border-bottom: 1px solid #BEBAB5;
        padding-bottom: 12px;
    }
}

.summary.company div b {
    font-weight: 400 !important;
}

ul.sub-menu li a {
    font-size: 14px !important;
    font-weight: 600 !important;
}

.label-table {
    td {
        border-top:none;
        padding-left: 0;
        border-bottom: 1px solid #BEBAB5;
    }
}

.table-header-grey {
    font-size: 14px;
    color:#86827E;
    font-weight: 600;
    border-bottom: 1px solid #BEBAB5 !important;
    border-top: none !important;
}

.letters {
    color: #86827E;
    font-weight: 600;
    font-size: 14px;
}