.title {
    font-size: 1.6rem;
    font-weight: 600;
    text-transform: uppercase;
    padding: 2rem 1rem;
    margin-bottom: 1rem;

    &--blue {
        background-color: $mainBlue;
        color: #fff;
        text-align: center;
    }

    &--white {
        background-color: #fff;
        color: $mainBlue;
        text-align: left;
        border-left: 5px solid $mainBlue;
        line-height: 2rem;
        margin: 0 1rem;
        padding: 0.5rem 0 0 1rem;
    }
}


/* debut collapsible fixed navbar */

#navbar {
    overflow: hidden;
    background-color: #fff;
    z-index: 10000;
    padding: 10px 7px;
    box-shadow: 0px 9px 9px -15px #111;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;

    /*min-height: $header-height;*/
    /*margin-top: .25rem;*/
    @media (min-width: $md) {
        padding: 1rem;
        padding-top: 8px !important;
        min-height: $header-height-md;
    }
}

.navbar-collapse {
    max-height: 100% !important;
}

/* The sticky class is added to the navbar with JS when it reaches its scroll position */
.sticky {
    position: fixed;
    top: 0;
    width: 100%;

    padding-top: $header-height;

    @media (min-width: $md) {
        padding-top: $header-height-md;
    }
}

#logo-header {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    display: block;
    margin: 0 auto;

    width: 109px;
    max-width: 109px;

    @media (min-width: $md) {
        width: 152px;
        max-width: 152px;
    }
}

#logo-header.sticked {

    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    width: 71px;
    height: 71px;
}

#logo-small {
    display: block;
    margin: 0 auto;

    width: 180px;
    max-width: 180px;
}

.switch--vertical.sticked {

    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    /*
	margin-top: 0 !important;
	margin-right: 0 !important;
	opacity: 0;

	padding-top: 0 !important;

	@media (min-width: $md) {
		opacity: 1;
		margin-top: 5px !important;
		margin-right: 5px !important;
	}
        */
    display: none;

}

#header-monespace {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;

    margin-top: 1rem;
}

#header-monespace.sticked {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;

    margin-top: 1rem;

    @media (min-width: $md) {
        margin-top: 2px;
    }
}

/* The sticky class is added to the navbar with JS when it reaches its scroll position */
.sticky {
    position: fixed;
    top: 0;
    width: 100%;

    padding-top: $header-height;

    @media (min-width: $md) {
        padding-top: $header-height-md;
    }
}

.navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    /*margin-bottom: 40px;*/
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);

    @media (min-width: $md) {
        padding-top: $header-height-md;
    }
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.navbar-toggler>.close {
    display: inline;
}

.navbar-toggler.collapsed>.close,
.navbar-toggler:not(.collapsed)>.navbar-toggler-icon {
    display: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

/*
.button-connect {
    float: right;
    margin-right: .5rem;
    @media (min-width: $md) {
        margin-right: 0;
    }
}*/
/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */


#dismiss {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background: #7386D5;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

#dismiss:hover {
    background: #fff;
    color: #7386D5;
}

.overlay {
    display: none;
    position: fixed;
    top: $header-height;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 998;
    opacity: 0;
    transition: all 0.5s ease-in-out;
}

.overlay.active {
    display: block;
    opacity: 1;
}

#sidebar .sidebar-header {
    padding: 20px;
    background: #6d7fcc;
}

#sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #47748b;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

/*
#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
    padding-bottom: 4px;
}*/

#sidebar ul li a:hover {
    /*color: #7386D5;
    background: #fff;*/
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
    color: #fff;
    background: #6d7fcc;
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

a.download {
    background: #fff;
    color: #7386D5;
}

a.article,
a.article:hover {
    background: #6d7fcc !important;
    color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    right: 0;
}
