/* 
* resources/sass/app.scss
*/

// Imports Tailwind CSS
@tailwind base;

@tailwind components;

@tailwind utilities;

// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
// Variables
@import 'variables';
// Bootstrap
@import '~bootstrap/scss/bootstrap';

$primary: blue;
