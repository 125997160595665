
.bottom-nav {
    margin: 0;
    padding: 2px 5px;
    height: 55px;
    border-top: 2px solid rgba(1, 190, 98, 0.5);
    
}

.bottom-nav--company {
    margin: 0;
    padding: 2px 5px;
    height: 55px;
    border-top: 2px solid #D9D1C8;
}


.bottom-button {
    display: inline-block;
    margin: 0 auto;
    &--text {
        font-size: 12px;
        line-height: 15px;
        text-align: center;
    }
}

.bottom-img {
    height: 35px;
}

/* override bootstrap postion for arrow*/

.dropdown-toggle--abo:after {
    top: 50%;
    right: 5px;
    width: 5px;
}

.dropdown-menu  a {
    font-size: 12px;
    line-height: 15px;
}