@font-face {
	font-family: 'Chalk Duster';
	src: url('/fonts/Chalkduster.ttf') format("truetype"),
		url('/fonts/Chalkduster.woff2') format('woff2'),
		url('/fonts/Chalkduster.woff') format('woff');

	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

:focus {
	outline: none !important;
}

b {
	font-weight: 600 !important;
}

button.disabled {
	opacity: 0.5;
	cursor: not-allowed !important;
}

.text-red {
	color: #E6511E !important;
}

.text-green {
	color: $mainGreen !important;
}


.grey {
	color: #86827E;
}

.modal-backdrop {
	opacity: 0.8 !important;
}

button:focus {
	outline: none !important;
}

::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #797C7F !important;
	opacity: 1;
	/* Firefox */
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #797C7F !important;
}

::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #797C7F !important;
}

p {
	font-size: 0.875rem !important;
	font-weight: 400 !important;
}

.text-med {
	font-size: 16px;
	line-height: 18.8px;
}

.text-small {
	font-size: .85rem;
}

.text-smaller {
	font-size: .75rem;
	line-height: .9rem !important;
}


.text-success {
	color: #81DEAF !important;
}

.text-danger {
	color: #E6511E !important;
}

.no-scroll {
	overflow: hidden;
	height: 100%;
}

[data-toggle='tooltip'] {
	cursor: default;
	/*font-family: 'Chalk Duster';*/
	background-color: transparent;
	color: #86827E;
	font-size: 14px;
	padding-top: 0;
	position: relative;
	top: -1px;
}

[data-toggle='tooltip'].classic-font {
	font-family: 'Public Sans', Arial, sans-serif;
	top: 0px !important;
}

html {
	min-height: 100%;
	/* make sure it is at least as tall as the viewport */
	position: relative;
}

body {
	height: 100%;
	/*background-color: #aaa;*/
	background-color: #fff;
	color: $mainText;

	font-family: 'Public Sans', Arial, sans-serif;
	font-size: 0.875rem;
	line-height: 1.2rem;
	padding-bottom: 50px;

	@media (min-width: $md) {
		padding-bottom: 0;
	}

}

img {
	/*max-width: 100%;*/
	height: auto;
}

a {
	color: black;
}

* {
	box-sizing: border-box;
}

.wrapper {
	width: 100%;
	margin: auto;

	@media (min-width: 950px) and (max-width: 1280px) {
		width: 950px
	}

	@media (min-width: 1280px) and (max-width: 1440px) {
		width: 1280px
	}

	@media (min-width: 1440px) {
		width: 1440px;
	}
}

.link-menu-custom {
	padding: 4px 10px !important;
	font-size: 12px !important;
	position: relative;
	/*top: -2px;
    right:-10px;
    */
	cursor: pointer;

	@media (min-width: $md) {
		padding: 11px 17px !important;
		font-size: 14px !important;
		top: 0px;
		right: 0px;
	}
}

.link-button {
	background: none;
	border: none;
	color: #000;
	cursor: pointer;
}


.logo {
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	display: block;
	width: 53px;
	height: 50px;

	@media (min-width: $md) {
		width: 69px;
		height: 66px;
	}
}

.link {
	font-weight: 700;
	cursor: pointer;
	color: #000;
	padding: 5px 6px;
	display: inline-block;
	font-size: 12px;
	text-justify: inter-word;

	@media (min-width: $md) {
		font-size: 15px;
		margin-bottom: .5rem;
	}

	&--white {
		color: #fff;
	}

	&--menu {
		font-weight: 700;
		white-space: nowrap;
		padding: 5px 6px;
		display: inline-block;
		text-justify: inter-word;
		font-family: Public Sans;
		font-size: 15px;
		font-weight: 700;
		line-height: 17.63px;
		text-align: center;
		margin: 0 20px 0 0;
		/*
		@media (min-width: $md) {
			font-size: 16px;
			margin: 0 10px;
		}
		*/
	}

	&--small {
		white-space: nowrap;
		color: #000;
		padding: 5px 6px;
		display: inline-block;
		text-justify: inter-word;
		font-size: 12px;
		text-decoration: underline;
	}

	&--smaller {
		white-space: nowrap;
		color: #000;
		display: inline-block;
		text-justify: inter-word;
		font-size: 10px;
		text-decoration: underline;
	}

	&--logout {
		background: none;
		margin: 1px 2px;
		padding: 0;

		@media (min-width: $md) {
			margin: 2px 2px;
			padding: 2px 0;
		}
	}

	&--under {
		font-size: 14px;
		text-decoration: underline;
	}

	&--greenborder {
		border: solid 1px $mainGreen;
	}

	&--redborder {
		border: solid 1px #E6511E !important;
	}

	&--greyborder {
		border: solid 1px #bebab5 !important;
	}

	&--greenbox {
		background-color: rgba(1, 190, 98, 0.5);
	}

	&--greybox {
		background-color: #bebab5;
	}

	&--greentext {
		color: $mainGreen !important;
	}

	&--box {
		border: solid 1px #bebab5;
		white-space: nowrap;
		display: inline-block;
		font-size: 1rem;
		padding: 1rem;
		text-align: center;
		text-justify: inter-word;
		/*margin: .5rem auto;*/
	}

	&--button {
		padding: 5px 30px;
		/*margin: 1rem;*/
		background-color: #fff;
		/*
		@media (min-width: $md) {
			margin: 1rem;
		}*/

		&--green {
			background-color: rgba(1, 190, 98, 0.5);
		}

		&--blue {
			background-color: #29B5E7;
			color: #fff;
		}

		&--dark-blue {
			background-color: #0B3F5B;
			color: #fff;
		}

		&--red {
			background-color: #E6511E;
			color: #fff;
		}
	}

	&--under {
		/*margin: 1rem;*/
		padding: 0;
		text-decoration: underline !important;
	}

	&--under-big {
		/*margin: 1rem;*/
		padding: 0;
		text-decoration: underline !important;
		font-size: 16px;
	}
}

.greentext {
	color: $mainGreen !important;
}

hr.green {
	border: 1px solid rgba(1, 190, 98, 0.5);

	&--w50 {
		width: 50px;
	}
}

.titl {
	font-weight: 700;
	font-size: 26px;
	line-height: 30px;

	@media (min-width: $md) {
		font-size: 40px;
		line-height: 47px;
	}

	&--big {
		font-size: 30px;
		line-height: 33px;
	}


	&--med {
		font-size: 26px;
		font-weight: 700;

		@media (min-width: $md) {
			font-size: 2.5rem;
		}
	}

	&--28 {
		font-size: 28px;
		font-weight: 700;
	}

	&--18 {
		font-size: 18px;
		font-weight: 700;
		line-height: 20px;
	}

	&--sm {
		font-size: 1.625rem;
	}

	&--green {
		color: $mainGreen;
		font-size: 18px;
		line-height: 21px !important;

		@media (min-width: $xl) {
			font-size: 28px;
			line-height: 32px !important;
		}
	}

	&--lightblue {
		color: $lightBlue;
	}

}

.text-grey {
	color: $redGrey;
	font-size: .94rem;
	font-weight: 400;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;

	@media (min-width: $md) {
		font-size: 1.125rem;
	}
}

.text-18-mobile-15 {
	/*font-size: 18px !important;*/
}

.text-12 {
	font-size: .75rem;
}

.text-10 {
	font-size: 10px;
}

.text-14 {
	font-size: .875rem;
}

.text-16 {
	font-size: 16px;
}

.text-15 {
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.125rem;

	@media (min-width: $md) {
		font-size: 17px !important;
	}

}

.text-28-mobile-20 {
	font-size: 28px;
}

.line-height-23 {
	line-height: 23px;
}

.line-height-17 {
	line-height: 17px !important;
}

.text-18-mobile-15 {
	font-size: 15px;

	@media (min-width: $xl) {
		font-size: 18px;
	}
}

.text-18-mobile-11 {
	font-size: 11px;

	@media (min-width: $xl) {
		font-size: 18px;
	}
}

.img-45 {
	height: 45px;
}


.text--green {
	color: $mainGreen;
	font-size: 1.125rem;
	font-weight: 600;

	.heading-step {
		font-size: 15px;
	}

	@media (min-width: $md) {
		.heading-step {
			font-size: 1.125rem;
		}
	}
}

.text--green-nosize {
	color: $mainGreen;
}

.heading-step {
	color: $mainGreen;
	font-size: 14px;
	font-weight: 600;

	@media (min-width: $md) {
		font-size: 1.125rem;
	}
}

.text--green--big {
	color: $mainGreen;
	font-size: 18px;
	font-weight: 700;

	@media (min-width: $md) {
		font-size: 28px
	}
}

.text--bold {
	font-weight: 700;
}

.text--green--small {
	color: $mainGreen;
	font-size: .875rem;

}

.text--grey--philosphy {
	color: #86827e;
	font-size: 15px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 20px;

	@media (min-width: $md) {
		font-size: 16px;
		line-height: 22px;
	}
}


button.focus,
button:focus {
	outline: 0;
	box-shadow: none !important
}

.message {
	font-size: 11px;
	color: #ee5535
}


#mCSB_1_container {
	height: 100vh;
}


#darkbackground {
	position: absolute;
	display: none;
	background-color: #000;
	opacity: 0.8;
	z-index: 99;
	top: 0;
	bottom: -68px;
	left: 0;
	right: 0;
	overflow: hidden;
}

.carousel-indicators {
	bottom: -47px !important;
}

.text-15-px {
	font-size: 15px;
	font-weight: 800;
}

#logo-header.stickyLogo {
	/*
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	width: 71px;
	@media (min-width: $md) {
		width: 71px;
	}
	*/
}

#navbar.sticked {
	/*
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	*/
	height: 52px !important;
}


.switch--vertical.sticked {
	/*
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	*/
	margin-top: 0 !important;
	margin-right: 0 !important;
	opacity: 0;

	@media (min-width: $md) {
		opacity: 1;
		margin-top: 5px !important;
		margin-right: 5px !important;
	}
}

#header-monespace {
	/*-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	*/
	margin-right: 5px;
	margin-top: 5px;

	/*
	@media (min-width: $md) {
		margin-right: 10px;
		margin-top: 20px;
	}
	*/
}

#header-monespace.sticked {
	/*-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
*/
	margin-right: 5px;
	margin-top: 5px;

}

.bouton-garantie {
	cursor: pointer;
}

.bg-grey {
	background-color: #FAF9F7;
}

.bg-cream {
	background-color: #FDFCFB;
}

.bg-green-light {
	background-color: #BFEED7;
}

.company .bg-green-light {
	background-color: #EDE8E4;
}

@media (min-width: $md) {
	.home-top {
		display: flex;
		flex-wrap: wrap;
		margin-right: -15px;
		margin-left: -15px;
	}

	/*
	ul.customUl li {
		font-size: 14px !important;
		padding-bottom: 7px;
	}
    */
}

.carousel-control-next,
.carousel-control-prev {
	display: none !important;
}

.carousel-indicators li {
	border-radius: 50%;
	width: 10px;
	height: 10px;
}

.carousel-indicators .active {
	background-color: #000;
}

ul.customUl {
	list-style-image: url('/img/lignes/fleche_verte.svg');
}

ul.customUl li {
	font-size: 15px;
	padding-bottom: 7px;
	line-height: 20px;
}

ul.dash {
	margin-top: 0 !important;
	list-style-type: none;
	/*use padding to move list item from left to right*/
	padding-left: 1em;
}

ul.dash li:before {
	content: "–";
	position: absolute;
	margin-left: -2em;
}

ul.dash li {
	margin-top: .5rem;
	margin-left: 3rem;
}


@media (max-width: $md) {
	.mode-emploi-container .number-svg {
		margin: 0 auto !important;
	}
}

.button-small {
	font-size: 12px;
	padding: 13px 20px !important;
}

.modal-header {
	border-bottom: 0 !important;
}

.modal-title {
	font-size: 17px;
	font-weight: 500;
}

.modal-header button.close span {
	font-size: 45px;
	font-weight: 100;
	position: absolute;
	right: 14px;
	top: 7px;
}

.bold {
	font-weight: 500 !important;
}

.bold-plus {
	font-weight: 600 !important;
}

.text-15 {
	font-size: 15px;
	line-height: 21px;
}

.philo-desktop {
	background-image: url(/img/blocs/philosophie_decoupe.svg);
}

.philo-desktop:before {
	background-image: url(/img/blocs/philosophie_decoupe.svg);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: right top;
	overflow: hidden;
	background-repeat: no-repeat;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: right top;
	content: '';
	display: block;
	position: absolute;
	z-index: -1;
	width: 999em;
	/* allow for bootstrap column padding */
	top: -15px;
	left: -15px;
	bottom: -15px;
}

.charter-content span {
	line-height: 23px !important;
}

.pin-picto {
	position: relative;
	bottom: 4px;
}

.text-green-mid-size {
	font-size: 16px !important;
}

.menu-separator {
	margin-left: 0 !important;
	display: block;
	border: 2px solid rgba(1, 190, 98, 0.7);
	;
	width: 50px;
	padding-left: 0;
	margin-bottom: 7px;
	margin-top: 7px;
}

.side-title {
	font-size: 15px;

	@media (min-width: $md) {
		font-size: 22px;
	}
}

.public-mobile-mini-menu {
	font-size: 15px;
	font-weight: 600;
	padding: 12px 11px;
	background-color: rgba(217, 209, 200, 0.7);
}

.public-mobile-mini-menu.active {
	background-color: rgba(1, 190, 98, 0.5);
	;
}

.text-3xl {
	font-size: 30px !important;

	@media (min-width: $md) {
		font-size: 48px !important;
	}
}

span.error {
	color: #E6511E !important;
}

.label-wej .bg-success {
	background-color: rgba(1, 190, 98, 0.5) !important;
}

.label-wej .bg-warning {
	background-color: #FFED00 !important;
}

.label-wej .bg-danger {
	background-color: #E6511E !important;
}

.color-menu-grey li a {
	color: #8f8d88;
}
