/* top left ribbon to link to sante*/
/*
.box {
    position: relative;
    max-width: 600px;
    width: 90%;
    height: 400px;
    background: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, .1);
}*/

/* common */
.ribbon {
    z-index: 100;
    /*top: 160px;*/
    left: 0px;
    width: 250px;
    height: 150px;
    overflow: hidden;
    position: absolute;
}

.ribbon span {
    position: absolute;
    display: block;
    left: -110px;
    top: 15px;
    transform: rotate(-45deg);
    width: 320px;
    padding: 15px;
    background-color: #009FE3;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
    color: #fff;
    font: 700 14px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    text-align: center;

}

.ribbon span a {
    font: 700 14px/1, sans-serif;
    text-align: center;
}
